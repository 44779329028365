.button{
    padding: 0.625rem 1.5625rem;

    border: none;
    border-radius: 0.3125rem;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;

    text-decoration: none;

    cursor: pointer;
}

.button-style{
    &--outline{
        padding: 0.3125rem 1.5625rem;

        background: none !important;
        border-width: 0.3125rem !important;
        border-style: solid !important;
    }

    &--thin{
        padding: 0.3125rem 1.5625rem;
    }
}

.button-background-color{
    &--blue{
        background-color: var(--color-secondary-500);
        border-color: var(--color-secondary-500);
    }

    &--black{
        background-color: black;
        border-color: black;
    }

    &--yellow{
        background-color: var(--color-primary-500);
        border-color: var(--color-primary-500);
    }
}

.button-text-color{
    &--blue{
        color: blue;
    }

    &--black{
        color: black;
    }

	&--white{
		color: white;
	}

    &--yellow{
        color: yellow;
    }
}
