.group {
    padding: 0 3.125rem;
    padding-bottom: 6rem;
    width: 90vw;
    margin: auto;

    display: flex;
    place-items: center;
    justify-content: center;
    flex-direction: column;

    h3{
        margin: 2.5rem 0;
        margin-top: 1.5rem;
    }

    ul{
        margin-bottom: 2.5rem;
    }
}