.big-card{
    width: 90vw;
    background: var(--color-primary-500);

    border-radius: 10px;

	overflow: hidden;

    display: flex;
    place-items: stretch;

    &--reverse{
        flex-direction: row-reverse;
    }
}


.big-card__text-container{
    width: 50%;
    padding: 3.125rem;

    display: flex;

    flex-direction: column;

    justify-content: center;
}

.big-card__title{
    font-family: "Montserrat", sans-serif;
    font-size: 3rem;
    font-weight: 700;
}

.big-card__text{
    margin-top: 1.5rem;

    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.5rem;
}

.big-card__image{
    width: 50%;

    object-fit: cover;
}

@media screen and(max-width: 1000px) {
    .big-card{
        width: calc(100vw - 50px);
        height: unset;

        flex-direction: column-reverse;
    }

    .big-card__text-container{
        width: 100%;
        height: unset;

        padding: 25px;

    }

    .big-card__image{
        width: 100%;
        height: unset;
    }
}
