.contact{
    padding: 3.125rem 0;

    background: var(--color-background-400);

    display: flex;

    flex-direction: column;

    place-items: center;
    justify-content: center;
}

.contact-container{
    width: 40vw;

    display: flex;

    flex-direction: column;

    place-items: center;
    justify-content: center;

    gap: 3.125rem;
}

.contact-info-container{
    width: 100%;

    display: flex;

    flex-direction: column;

    gap: 1.5625rem;
}

.contact-info-element{
    padding: 1.5625rem;

    display: flex;
    place-items: center;

    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.64rem;

    background: #202020;
    color: white;

    fill: white;

    text-decoration: none;

    border-radius: 0.625rem;

    svg{
        width: 3.125rem;
        margin-right: 1.5625rem;
    }
}

.contact-form{
    width: 100%;

    display: flex;

    flex-direction: column;

    place-items: center;

    gap: 1.5625rem;
}

.contact-form__success{
    width: 100%;
    padding: 1rem;

    font-size: 1.75rem;

    color: white;
    background: #4aed91;

    border-radius: 0.3125rem;
}

.contact-form__error{
    width: 100%;
    padding: 1rem;

    font-size: 1.75rem;

    color: white;
    background: #ed4a57;

    border-radius: 0.3125rem;
}

.contact-form__input{
    width: 100%;
	height: 4rem;

    background: none;
    border: none;
    outline: none;

    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.5rem;
    line-height: 3.875rem;

    border-bottom: 0.125rem solid black;

    resize: vertical;

    &::placeholder{
        color: black;
        opacity: 1;
    }
}

.contact-form__textarea{
    // padding-bottom: 0;
}


.contact-form__checkbox-container{
    display: flex;

}

.contact-form__checkbox{
    width: 1.5625rem;
    height: 1.5625rem;

    margin-right: 0.9375rem;

    flex-shrink: 0;

    appearance: none;
    background: none;

    border: 0.125rem solid black;
    border-radius: 0.3125rem;

    cursor: pointer;
}

.contact-form__checkbox:checked{
    background: var(--color-primary-500) !important;
}

@media screen and(max-width: 1000px) {
    .contact-container{
        width: calc(100vw - 50px);
    }

    .contact-info-element{
        padding: 20px;

        display: flex;
        place-items: center;

        font-family: "Source Sans Pro", sans-serif;
        font-size: 1.64rem;

        background: #202020;
        color: white;

        fill: white;

        text-decoration: none;

        border-radius: 5px;

        svg{
            width: 35px;
            margin-right: 20px;
        }
    }

    .contact-form__textarea{
        // padding: 10px !important;
    }
}
