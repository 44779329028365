.coop{
    padding: 3.125rem 0;

    display: flex;

    flex-direction: column;

    place-items: center;

	&__text{
		width: 80vw;
		margin-top: 3.125rem;

		font-family: "Source Sans Pro", sans-serif;
		font-size: 1.75rem;
	}
}

@media screen and (max-width: 1000px) {
	.coop__text{
		width: calc(100vw - 100px);
	}
}
