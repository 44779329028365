.about{
    width: 100%;
    padding: 3.125rem 0;

    display: flex;

    place-items: center;
    justify-content: center;
}

.about-container{
    background: var(--color-primary-500);
}