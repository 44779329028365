main {
    height: var(--height);
    min-height: 40rem;

    display: flex;

    position: relative;
}

.main-grid {
    width: 100%;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 100%;

    &__left {
        padding: 3.125rem;

        background: white;

        display: flex;

        place-items: flex-end;
        justify-content: center;
    }

    &__right {
        padding-right: 100px;

        background: var(--color-primary-500);

        display: flex;

        place-items: center;
        justify-content: flex-end;
    }
}

.main-cta {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 2.25rem;

    text-decoration: none;
    color: black;

    display: flex;
    place-items: center;
    gap: 3.125rem;

    &__arrow-container {
        width: 4rem;
        height: 4rem;

        border-radius: 100%;

        background: #252525;

        display: flex;
        place-items: center;
        justify-content: center;
    }

    &__arrow {
        width: 1.5rem;
    }
}

.main-text {
    width: 50vw;

    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 2.5rem;

    position: absolute;

    left: 10rem;
    top: 50%;

    transform: translateY(-50%);
}

.main-image {
    width: 50%;
    min-width: 20rem;
    max-width: 50vh;

    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
}

.main-mobile {
    display: none;
}

@media screen and(max-width: 1500px) {
    .main-grid {
        // grid-template-columns: 40vw 60vw;

        &__left {
            padding-bottom: 25px;
        }
    }

    .main-cta {
        font-size: 1.75rem;
    }
}

@media screen and(max-width: 1000px) {
    main {
        min-height: 450px;
        display: none !important;
    }

    .main-mobile {
        min-height: 450px;
        padding: 2rem;
        margin-top: 8rem;

        display: flex !important;
        flex-direction: column;
        place-items: center;
        justify-content: space-between;

        background: var(--color-primary-500);
    }

    .main-grid {
        grid-template-columns: 1fr;

        &__left {
            padding-bottom: 25px;

            background: var(--color-primary-500);
        }

        &__right {
            // display: none;
        }
    }

    .main-text {
        align-self: flex-start;
        width: 80vw;

        top: 10rem;
        left: 2rem;

        transform: unset !important;
        position: unset;
        // transform: translate(-50%, -50%);

        font-size: 2rem;
    }

    .main-image {
        width: 30vw;
        margin: 2rem 0;
        aspect-ratio: 1;
        max-width: unset;

        max-height: unset;

        position: unset;

        left: 50%;
        top: calc(50% + 4rem);

        // transform: translate(-50%, -50%);
    }
}
