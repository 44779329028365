.offer {
    padding: 3.125rem 0;

    display: flex;

    flex-direction: column;

    place-items: center;

    background: var(--color-background-400);
}

.offer-grid {
    width: 90vw;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5625rem;
}

.offer-card:hover > .offer-card__hover {
    transform: translateY(0);
}

.offer-card {
    width: 100%;
    // height: 100%;

    padding: 1.5rem;

    border-radius: 0.3125rem;

    background: var(--color-primary-500);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;

    overflow: hidden;

    &__top {
    }

    &__bottom {
    }

    &__header {
        display: flex;
        // place-items: center;
        justify-content: space-between;
    }

    &__title {
        width: 100%;

        font-family: "Source Sans Pro", sans-serif;
        font-weight: 700;
        font-size: 1.7rem;

        span {
            margin-right: 2rem;
        }
    }

    &__svg-container {
        width: 3rem;
        margin-left: 1rem;
    }

    &__text {
        margin-top: 1.5rem;

        font-family: "Source Sans Pro", sans-serif;
        font-size: 1.25rem;
    }

    &__cta-container {
        justify-self: flex-end;
        display: flex;
        flex-direction: column;

        gap: 1rem;

        &:not(:empty) {
            margin-top: 1.5rem;
        }
    }
}

.offer-card-hover {
    width: 100%;
    height: 100%;

    padding: 1.5rem;

    background: white;

    border-radius: 0.3125rem;

    display: flex;

    flex-direction: column;

    place-items: center;
    justify-content: center;

    position: absolute;

    top: 0;
    left: 0;

    transition: transform 0.3s ease-in-out;

    overflow: hidden;

    &__svg-container {
        width: 10rem;
        height: 100%;
        margin: 50px 0;

        fill: var(--color-primary-500);

        display: flex;
    }

    &__text {
        width: 100%;
    }

    &__subtitle {
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        font-size: 0.875rem;

        color: #585858;
        text-transform: uppercase;

        text-align: center;

        span {
            white-space: nowrap;
            margin-right: 1rem;
        }
    }

    &__title {
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 700;
        font-size: 1.75rem;

        text-align: center;
    }
}

.offer-card:hover > .offer-card-hover {
    transform: translateY(-100%);
}

@media screen and(max-width: 1500px) {
    .offer-grid {
        width: 90vw;
    }
}

@media screen and(max-width: 1000px) {
    .offer-grid {
        width: calc(100vw - 50px);

        grid-template-columns: 1fr;
        grid-template-rows: unset !important;
        grid-auto-rows: auto;
    }

    .offer-card-hover {
        transform: translateY(-100%);
    }
}
