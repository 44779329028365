nav{
    width: 100%;
    height: 8rem;

    display: flex;

    place-items: center;
    justify-content: center;

    position: absolute;

    left: 0;
    top: 0;

    *{ z-index: 1; }
}

.nav-container{
    width: 80vw;

    display: flex;

    place-items: center;
    justify-content: space-between;
}

.nav-logo{
    height: 5rem;
}

.nav-links{
    display: flex;
    place-items: center;

    gap: 3.125rem;
}

.nav-link{
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 1.5rem;

    text-decoration: none;

    color: black;
}

.nav-burger{
    width: 35px;
    height: 25px;

    display: none;

    flex-direction: column;
    justify-content: space-between;

    cursor: pointer;

    z-index: 11;

    &__line{
        width: 100%;
        height: 3px;

        background: black;

        transition: transform .2s ease-in-out;
    }
}

.nav-burger--active{
    :nth-child(1) {
        transform: translateY(11px) rotateZ(45deg);
    }

    :nth-child(2) {
        transform: rotate(45deg);
    }

    :nth-child(3) {
        transform: translateY(-11px) rotateZ(-45deg);
    }

}

@media screen and(max-width: 1500px) {
    .nav-container{
        width: 90vw;
    }
}

@media screen and(max-width: 1000px) {
    nav{
        background: white;

        z-index: 1;

        position: fixed;
    }

    .nav-logo{
        height: 50px;
    }

    .nav-links{
        width: 100%;
		min-height: var(--height);
        height: var(--height);

        position: fixed;

        left: 0;
        top: 0;

        z-index: 10;

        transform: translateX(-100%);

        transition: transform .2s ease-in-out;

        flex-direction: column;
        justify-content: center;

        background: var(--color-primary-500);
		overflow: hidden;

        &--shown{
            transform: translate(0);
        }
    }

    .nav-burger{
        display: flex;
    }
}
