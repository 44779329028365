.hidden{
	border: 1px solid red;
	display: flex !important;
}

.multipart-form{
	width: 100%;
	height: var(--height) !important;
	// height: -webkit-fill-available;
	// height: 100% !important;

	padding: 3.125rem;

	overflow-y: scroll;

	position: fixed;

	left: 0;
	top: 0;

	background: #202020;

	display: none;

	place-items: center;
	justify-content: space-between;

	flex-direction: column;

	z-index: 10;
	*{ z-index: 10; }

	&--shown{
		display: flex !important;
	}

	&__header{
		width: 100%;
		align-self: flex-start;

		display: flex;

		place-items: flex-start;
		justify-content: space-between;

		gap: 3.125rem;
	}

	&__header-title{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 2.25rem;
		font-weight: 600;

		color: white;
	}

	&__cross{
		width: 3rem;
		height: 3rem;

		display: flex;

		flex-direction: column;

		place-items: center;
		justify-content: center;

		cursor: pointer;
		
		flex-shrink: 0;
	}

	&__cross-line{
		width: 100%;
		height: 0.1875rem;

		background: white;

		&:nth-child(1) {
			transform: rotate(45deg) translateY(0.125rem);
		}

		&:nth-child(2) {
			transform: rotate(-45deg) translateY(-0.125rem);
		}
	}

	&__main-container{
		width: 100%;

		padding: 3.125rem 0;

		display: flex;
	}

	&__pages-container{

		width: 100%;
		height: 100%;

		z-index: 11;
	}

	&__page{

	}

	&__input{
		padding: 0.625rem;

		background: none;

		outline: none;

		border: none;
		border-radius: 0;

		border-bottom: 0.1875rem solid white;

		color: white;

		font-family: "Source Sans Pro", sans-serif;
		font-size: 1.5rem;
		font-weight: 600;

		&--error{
			border-color: #ed4a57;
		}

		&::placeholder{
			color: white;
			opacity: 1;
		}

		&:focus{
			border-color: var(--color-primary-500);
		}
	}

	&__select{
		option{
			color: black;
		}
	}

	&__navigation{
		width: 80%;

		display: flex;
		place-items: center;
		justify-content: space-between;

		z-index: 0 !important;

		flex-direction: column;
		gap: 2.25rem;

		.button{
			width: 17.5rem;
		}

		&-buttons{
			display: flex;

			gap: 1.5625rem;
		}
	}
}

.multipart-form-status{
	padding: 6.25rem;
	padding-top: 0;

	display: flex;
	flex-direction: column;

	gap: 2rem;

	&--current{
		.multipart-form-status__number{
			border-color: var(--color-primary-500) !important;
		}
	}

	&__element{
		display: flex;

		place-items: center;

		gap: 1.5625rem;
	}

	&__number{
		width: 5rem;
		height: 5rem;

		font-family: "Source Sans Pro", sans-serif;
		font-weight: 600;
		font-size: 2.25rem;

		color: white;

		border-radius: 100%;
		border: 3px solid white;

		flex-shrink: 0;

		display: flex;
		place-items: center;
		justify-content: center;
	}

	&__title{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 1.5rem;
		font-weight: 600;

		color: white;

		white-space: nowrap;
	}
}

.multipart-form-checkbox{
	padding: 0.625rem;
	display: flex;

	place-items: center;

	gap: 1.5625rem;

	cursor: pointer;

	&--error{
		.multipart-form-checkbox__input{
			border-color: #ed4a57 !important;
		}
	}

	&__input{
		width: 1.875rem;
		height: 1.875rem;

		appearance: none;

		border: 0.1875rem solid white;
		border-radius: 0.3125rem;

		cursor: pointer;

		flex-shrink: 0;

		&:checked{
			background: var(--color-primary-500);
			border-color: var(--color-primary-500);
		}
	}

	&__text{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 1.5rem;
		font-weight: 600;

		color: white;
	}
}

#car-insurance-rodo__text{
	font-size: 1rem;
}

#insurance-form-personal-data{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5625rem;

	grid-template-areas: 
	"firstname firstname"
	"pesel pesel"
	"address address"
	"city postcode"
	"phone phone"
	"email email"
	"leasing leasing"
	"companyName regon";
}



.multipart-form-select{
	position: relative;
	z-index: 11;
	*{z-index: 11;}

	&:hover{
		.multipart-form-select__triangle-container svg{
			transform: rotateZ(270deg);
		}

		.multipart-form-select__options-container{
			display: flex !important;
		}
	}

	&--error{
		.multipart-form-select__current-option{
			border-color: rgb(237, 74, 87);
		}
	}

	&__current-option {
		padding: 0.625rem;

		display: flex;

		place-items: center;
		justify-content: space-between;

		border-bottom: 0.1875rem solid white;

		color: white;

		font-family: "Source Sans Pro", sans-serif;
		font-size: 1.5rem;
		font-weight: 600;

		position: relative;

		cursor: pointer;
	}

	&__triangle-container{
		aspect-ratio: 1/1;
		height: 100%;

		position: absolute;

		right: 0;
		top: 0;

		display: flex;
		place-items: center;
		justify-content: center;

		svg{
			height: 40%;

			fill:white;

			transform: rotateZ(180deg);

			transition: transform .1s ease-in-out;
		}
	}

	&__options-container{
		width: 100%;

		position: absolute;

		bottom: 0;
		transform: translateY(100%);

		display: none;

		flex-direction: column;

		&--hidden{
			border: 1px solid red;
		}
	}

	&__option{
		padding: 1rem 1rem;

		color: white;

		font-family: "Source Sans Pro", sans-serif;
		font-size: 1.5rem;
		font-weight: 600;

		cursor: pointer;

		z-index: 11 !important;

		display: flex;
		place-items: center;
		gap: 1rem;

		&:nth-child(even) {
			background: #303030;
		}

		&:nth-child(odd) {
			background: #404040;
		}
	}

	&__option-checkbox{
		width: 1.875rem;
		height: 1.875rem;

		appearance: none;

		border: 0.1875rem solid white;
		border-radius: 0.3125rem;

		cursor: pointer;

		&:checked{
			background: var(--color-primary-500);
			border-color: var(--color-primary-500);
		}
	}
}



#car-insurance-firstname{grid-area: firstname;}
#car-insurance-lastname {grid-area: lastname;}
#car-insurance-pesel	{grid-area: pesel;}
#car-insurance-address	{grid-area: address;}
#car-insurance-city		{grid-area: city;}
#car-insurance-postcode {grid-area: postcode;}
#car-insurance-phone 	{grid-area: phone;}
#car-insurance-email 	{grid-area: email;}
#car-insurance-companyName 	{grid-area: companyName;}
#car-insurance-regon 	{grid-area: regon;}

#insurance-form-car-data{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5625rem;

	grid-template-areas:
	"brand model"
	"vehicle-type production-year"
	"registration-number vin-number"
	"insurance-range policy-number"
}

#car-insurance-brand			  {grid-area: brand}
#car-insurance-model			  {grid-area: model}
#car-insurance-vehicle-type		  {grid-area: vehicle-type}
#car-insurance-production-year	  {grid-area: production-year}
#car-insurance-registration-number{grid-area: registration-number}
#car-insurance-vin-number		  {grid-area: vin-number}
#car-insurance-leasing			  {grid-area: leasing}
#car-insurance-insurance-range	  {grid-area: insurance-range}
#car-insurance-policy-number	  {grid-area: policy-number}


@media screen and(max-width: 1000px) {
	.multipart-form{
		// height: unset;
		// min-height: 100vh;
		// max-height: 100vh;

		padding: 1.5625rem;

		&__main-container{
			height: unset !important;
			flex-direction: column-reverse;
		}

		&__navigation{
			width: 100%;

			flex-direction: column;
			gap: 2.25rem;

			.multipart-form-checkbox{
				flex-shrink: 0;
			}
		}

		&__navigation-buttons{
			width: 100%;

			display: flex;

			place-items: center;
			justify-content: center;
		}

		&-sendStatus{
			text-align: center;
		}
	}

	.multipart-form-status{
		padding-left: 0;
		padding-bottom: 3.125rem;
	}

	.multipart-form-select--shown{
		.multipart-form-select__triangle-container svg{
			transform: rotateZ(270deg);
		}

		.multipart-form-select__options-container{
			display: flex !important;
		}
	}

	.multipart-form-select{
		&:hover{
			.multipart-form-select__triangle-container svg{
				transform: rotateZ(0);
			}

			.multipart-form-select__options-container{
				display: none;
			}
		}
	}

	#insurance-form-personal-data,
	#insurance-form-car-data
	{
		display: grid;
		grid-template-columns: 1fr !important;
		grid-template-areas: unset !important;
	}

	.multipart-form__input{
		grid-area: unset !important;
	}
}

.multipart-form-sendStatus{
	height: 100%;

	color: white;
	fill: white;

	display: flex;

	place-items: center;
	justify-content: center;

	flex-direction: column;

	svg{
		width: 10rem;
		margin-bottom: 2rem;
	}

	&__title{
		font-size: 2rem;
		font-family: "Barlow", sans-serif;
		font-weight: 600;
	}

	&__description{
		margin-top: 0.25rem;

		font-size: 1.25rem;
		font-family: "Barlow", sans-serif;

		color: #dddddd;
	}
}
