footer {
    padding: 1rem;

    background: #202020;

    display: flex;

    place-items: center;
    justify-content: space-between;

    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;

    color: white;
}

.footer {
    &__left {
        display: grid;
        grid-gap: 1rem;
    }

    &__left-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    &__left-bottom{
        display: grid;
        grid-gap: 1rem;
    }
}

@media screen and(max-width: 1000px) {
    footer {
        flex-direction: column;

        text-align: center;
    }
    .footer {
        &__left {
            margin-bottom: 2.5rem;
        }

        &__left-grid {
            grid-template-columns: 1fr;
            place-items: center;

        }
    }
}
