@import "./abstracts/colors";
@import "./abstracts/typography";

@import "./layout/nav";
@import "./layout/main";
@import "./layout/offer";
@import "./layout/about";
@import "./layout/contact";
@import "./layout/cooperation";
@import "./layout/group";
@import "./layout/footer";

@import "./components/buttons";
@import "./components/cards";
@import "./components/multipart-form";

*{
    margin: 0;
    padding: 0;

    box-sizing: border-box;
}

html{
    width: 100%;
    
    scroll-behavior: smooth;

    --height: 100vh;
}

.section-title{
    margin-bottom: 3.125rem;

    text-align: center;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 2.25rem;
}