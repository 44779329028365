@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Source+Sans+Pro:wght@400;600;700&display=swap");
:root {
  --color-primary-500: #eaaf3c;
  --color-secondary-500: #01799C;
  --color-background-500: #EBEBEB;
  --color-background-400: #EBEBEB;
}

* {
  font-family: "Source Sans Pro", sans-serif;
}

@media screen and (min-width: 2000px) {
  :root {
    font-size: 20px;
  }
}
@media screen and (min-width: 3000px) {
  :root {
    font-size: 30px;
  }
}
@media screen and (min-width: 4000px) {
  :root {
    font-size: 40px;
  }
}
@media screen and (min-width: 5000px) {
  :root {
    font-size: 50px;
  }
}
@media screen and (min-width: 6000px) {
  :root {
    font-size: 55px;
  }
}
@media screen and (min-width: 7000px) {
  :root {
    font-size: 60px;
  }
}
@media screen and (min-width: 8000px) {
  :root {
    font-size: 65px;
  }
}
@media screen and (max-width: 1500px) {
  :root {
    font-size: 12px;
  }
}
@media screen and (max-width: 1000px) {
  :root {
    font-size: 10px;
  }
}
nav {
  width: 100%;
  height: 8rem;
  display: flex;
  place-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
}
nav * {
  z-index: 1;
}

.nav-container {
  width: 80vw;
  display: flex;
  place-items: center;
  justify-content: space-between;
}

.nav-logo {
  height: 5rem;
}

.nav-links {
  display: flex;
  place-items: center;
  gap: 3.125rem;
}

.nav-link {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
}

.nav-burger {
  width: 35px;
  height: 25px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  z-index: 11;
}
.nav-burger__line {
  width: 100%;
  height: 3px;
  background: black;
  transition: transform 0.2s ease-in-out;
}

.nav-burger--active :nth-child(1) {
  transform: translateY(11px) rotateZ(45deg);
}
.nav-burger--active :nth-child(2) {
  transform: rotate(45deg);
}
.nav-burger--active :nth-child(3) {
  transform: translateY(-11px) rotateZ(-45deg);
}

@media screen and (max-width: 1500px) {
  .nav-container {
    width: 90vw;
  }
}
@media screen and (max-width: 1000px) {
  nav {
    background: white;
    z-index: 1;
    position: fixed;
  }

  .nav-logo {
    height: 50px;
  }

  .nav-links {
    width: 100%;
    min-height: var(--height);
    height: var(--height);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;
    flex-direction: column;
    justify-content: center;
    background: var(--color-primary-500);
    overflow: hidden;
  }
  .nav-links--shown {
    transform: translate(0);
  }

  .nav-burger {
    display: flex;
  }
}
main {
  height: var(--height);
  min-height: 40rem;
  display: flex;
  position: relative;
}

.main-grid {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%;
}
.main-grid__left {
  padding: 3.125rem;
  background: white;
  display: flex;
  place-items: flex-end;
  justify-content: center;
}
.main-grid__right {
  padding-right: 100px;
  background: var(--color-primary-500);
  display: flex;
  place-items: center;
  justify-content: flex-end;
}

.main-cta {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
  text-decoration: none;
  color: black;
  display: flex;
  place-items: center;
  gap: 3.125rem;
}
.main-cta__arrow-container {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background: #252525;
  display: flex;
  place-items: center;
  justify-content: center;
}
.main-cta__arrow {
  width: 1.5rem;
}

.main-text {
  width: 50vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
  position: absolute;
  left: 10rem;
  top: 50%;
  transform: translateY(-50%);
}

.main-image {
  width: 50%;
  min-width: 20rem;
  max-width: 50vh;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 100%;
}

.main-mobile {
  display: none;
}

@media screen and (max-width: 1500px) {
  .main-grid__left {
    padding-bottom: 25px;
  }

  .main-cta {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 1000px) {
  main {
    min-height: 450px;
    display: none !important;
  }

  .main-mobile {
    min-height: 450px;
    padding: 2rem;
    margin-top: 8rem;
    display: flex !important;
    flex-direction: column;
    place-items: center;
    justify-content: space-between;
    background: var(--color-primary-500);
  }

  .main-grid {
    grid-template-columns: 1fr;
  }
  .main-grid__left {
    padding-bottom: 25px;
    background: var(--color-primary-500);
  }
  .main-text {
    align-self: flex-start;
    width: 80vw;
    top: 10rem;
    left: 2rem;
    transform: unset !important;
    position: unset;
    font-size: 2rem;
  }

  .main-image {
    width: 30vw;
    margin: 2rem 0;
    aspect-ratio: 1;
    max-width: unset;
    max-height: unset;
    position: unset;
    left: 50%;
    top: calc(50% + 4rem);
  }
}
.offer {
  padding: 3.125rem 0;
  display: flex;
  flex-direction: column;
  place-items: center;
  background: var(--color-background-400);
}

.offer-grid {
  width: 90vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1.5625rem;
}

.offer-card:hover > .offer-card__hover {
  transform: translateY(0);
}

.offer-card {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.3125rem;
  background: var(--color-primary-500);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.offer-card__header {
  display: flex;
  justify-content: space-between;
}
.offer-card__title {
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 1.7rem;
}
.offer-card__title span {
  margin-right: 2rem;
}
.offer-card__svg-container {
  width: 3rem;
  margin-left: 1rem;
}
.offer-card__text {
  margin-top: 1.5rem;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.25rem;
}
.offer-card__cta-container {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.offer-card__cta-container:not(:empty) {
  margin-top: 1.5rem;
}

.offer-card-hover {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background: white;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}
.offer-card-hover__svg-container {
  width: 10rem;
  height: 100%;
  margin: 50px 0;
  fill: var(--color-primary-500);
  display: flex;
}
.offer-card-hover__text {
  width: 100%;
}
.offer-card-hover__subtitle {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #585858;
  text-transform: uppercase;
  text-align: center;
}
.offer-card-hover__subtitle span {
  white-space: nowrap;
  margin-right: 1rem;
}
.offer-card-hover__title {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  text-align: center;
}

.offer-card:hover > .offer-card-hover {
  transform: translateY(-100%);
}

@media screen and (max-width: 1500px) {
  .offer-grid {
    width: 90vw;
  }
}
@media screen and (max-width: 1000px) {
  .offer-grid {
    width: calc(100vw - 50px);
    grid-template-columns: 1fr;
    grid-template-rows: unset !important;
    grid-auto-rows: auto;
  }

  .offer-card-hover {
    transform: translateY(-100%);
  }
}
.about {
  width: 100%;
  padding: 3.125rem 0;
  display: flex;
  place-items: center;
  justify-content: center;
}

.contact {
  padding: 3.125rem 0;
  background: var(--color-background-400);
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
}

.contact-container {
  width: 40vw;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  gap: 3.125rem;
}

.contact-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5625rem;
}

.contact-info-element {
  padding: 1.5625rem;
  display: flex;
  place-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.64rem;
  background: #202020;
  color: white;
  fill: white;
  text-decoration: none;
  border-radius: 0.625rem;
}
.contact-info-element svg {
  width: 3.125rem;
  margin-right: 1.5625rem;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 1.5625rem;
}

.contact-form__success {
  width: 100%;
  padding: 1rem;
  font-size: 1.75rem;
  color: white;
  background: #4aed91;
  border-radius: 0.3125rem;
}

.contact-form__error {
  width: 100%;
  padding: 1rem;
  font-size: 1.75rem;
  color: white;
  background: #ed4a57;
  border-radius: 0.3125rem;
}

.contact-form__input {
  width: 100%;
  height: 4rem;
  background: none;
  border: none;
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  line-height: 3.875rem;
  border-bottom: 0.125rem solid black;
  resize: vertical;
}
.contact-form__input::placeholder {
  color: black;
  opacity: 1;
}

.contact-form__checkbox-container {
  display: flex;
}

.contact-form__checkbox {
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.9375rem;
  flex-shrink: 0;
  appearance: none;
  background: none;
  border: 0.125rem solid black;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.contact-form__checkbox:checked {
  background: var(--color-primary-500) !important;
}

@media screen and (max-width: 1000px) {
  .contact-container {
    width: calc(100vw - 50px);
  }

  .contact-info-element {
    padding: 20px;
    display: flex;
    place-items: center;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.64rem;
    background: #202020;
    color: white;
    fill: white;
    text-decoration: none;
    border-radius: 5px;
  }
  .contact-info-element svg {
    width: 35px;
    margin-right: 20px;
  }
}
.coop {
  padding: 3.125rem 0;
  display: flex;
  flex-direction: column;
  place-items: center;
}
.coop__text {
  width: 80vw;
  margin-top: 3.125rem;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.75rem;
}

@media screen and (max-width: 1000px) {
  .coop__text {
    width: calc(100vw - 100px);
  }
}
.group {
  padding: 0 3.125rem;
  padding-bottom: 6rem;
  width: 90vw;
  margin: auto;
  display: flex;
  place-items: center;
  justify-content: center;
  flex-direction: column;
}
.group h3 {
  margin: 2.5rem 0;
  margin-top: 1.5rem;
}
.group ul {
  margin-bottom: 2.5rem;
}

footer {
  padding: 1rem;
  background: #202020;
  display: flex;
  place-items: center;
  justify-content: space-between;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
}

.footer__left {
  display: grid;
  grid-gap: 1rem;
}
.footer__left-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.footer__left-bottom {
  display: grid;
  grid-gap: 1rem;
}

@media screen and (max-width: 1000px) {
  footer {
    flex-direction: column;
    text-align: center;
  }

  .footer__left {
    margin-bottom: 2.5rem;
  }
  .footer__left-grid {
    grid-template-columns: 1fr;
    place-items: center;
  }
}
.button {
  padding: 0.625rem 1.5625rem;
  border: none;
  border-radius: 0.3125rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  text-decoration: none;
  cursor: pointer;
}

.button-style--outline {
  padding: 0.3125rem 1.5625rem;
  background: none !important;
  border-width: 0.3125rem !important;
  border-style: solid !important;
}
.button-style--thin {
  padding: 0.3125rem 1.5625rem;
}

.button-background-color--blue {
  background-color: var(--color-secondary-500);
  border-color: var(--color-secondary-500);
}
.button-background-color--black {
  background-color: black;
  border-color: black;
}
.button-background-color--yellow {
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}

.button-text-color--blue {
  color: blue;
}
.button-text-color--black {
  color: black;
}
.button-text-color--white {
  color: white;
}
.button-text-color--yellow {
  color: yellow;
}

.big-card {
  width: 90vw;
  background: var(--color-primary-500);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  place-items: stretch;
}
.big-card--reverse {
  flex-direction: row-reverse;
}

.big-card__text-container {
  width: 50%;
  padding: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.big-card__title {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: 700;
}

.big-card__text {
  margin-top: 1.5rem;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
}

.big-card__image {
  width: 50%;
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  .big-card {
    width: calc(100vw - 50px);
    height: unset;
    flex-direction: column-reverse;
  }

  .big-card__text-container {
    width: 100%;
    height: unset;
    padding: 25px;
  }

  .big-card__image {
    width: 100%;
    height: unset;
  }
}
.hidden {
  border: 1px solid red;
  display: flex !important;
}

.multipart-form {
  width: 100%;
  height: var(--height) !important;
  padding: 3.125rem;
  overflow-y: scroll;
  position: fixed;
  left: 0;
  top: 0;
  background: #202020;
  display: none;
  place-items: center;
  justify-content: space-between;
  flex-direction: column;
  z-index: 10;
}
.multipart-form * {
  z-index: 10;
}
.multipart-form--shown {
  display: flex !important;
}
.multipart-form__header {
  width: 100%;
  align-self: flex-start;
  display: flex;
  place-items: flex-start;
  justify-content: space-between;
  gap: 3.125rem;
}
.multipart-form__header-title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  color: white;
}
.multipart-form__cross {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}
.multipart-form__cross-line {
  width: 100%;
  height: 0.1875rem;
  background: white;
}
.multipart-form__cross-line:nth-child(1) {
  transform: rotate(45deg) translateY(0.125rem);
}
.multipart-form__cross-line:nth-child(2) {
  transform: rotate(-45deg) translateY(-0.125rem);
}
.multipart-form__main-container {
  width: 100%;
  padding: 3.125rem 0;
  display: flex;
}
.multipart-form__pages-container {
  width: 100%;
  height: 100%;
  z-index: 11;
}
.multipart-form__input {
  padding: 0.625rem;
  background: none;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 0.1875rem solid white;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}
.multipart-form__input--error {
  border-color: #ed4a57;
}
.multipart-form__input::placeholder {
  color: white;
  opacity: 1;
}
.multipart-form__input:focus {
  border-color: var(--color-primary-500);
}
.multipart-form__select option {
  color: black;
}
.multipart-form__navigation {
  width: 80%;
  display: flex;
  place-items: center;
  justify-content: space-between;
  z-index: 0 !important;
  flex-direction: column;
  gap: 2.25rem;
}
.multipart-form__navigation .button {
  width: 17.5rem;
}
.multipart-form__navigation-buttons {
  display: flex;
  gap: 1.5625rem;
}

.multipart-form-status {
  padding: 6.25rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.multipart-form-status--current .multipart-form-status__number {
  border-color: var(--color-primary-500) !important;
}
.multipart-form-status__element {
  display: flex;
  place-items: center;
  gap: 1.5625rem;
}
.multipart-form-status__number {
  width: 5rem;
  height: 5rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
  color: white;
  border-radius: 100%;
  border: 3px solid white;
  flex-shrink: 0;
  display: flex;
  place-items: center;
  justify-content: center;
}
.multipart-form-status__title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  white-space: nowrap;
}

.multipart-form-checkbox {
  padding: 0.625rem;
  display: flex;
  place-items: center;
  gap: 1.5625rem;
  cursor: pointer;
}
.multipart-form-checkbox--error .multipart-form-checkbox__input {
  border-color: #ed4a57 !important;
}
.multipart-form-checkbox__input {
  width: 1.875rem;
  height: 1.875rem;
  appearance: none;
  border: 0.1875rem solid white;
  border-radius: 0.3125rem;
  cursor: pointer;
  flex-shrink: 0;
}
.multipart-form-checkbox__input:checked {
  background: var(--color-primary-500);
  border-color: var(--color-primary-500);
}
.multipart-form-checkbox__text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}

#car-insurance-rodo__text {
  font-size: 1rem;
}

#insurance-form-personal-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5625rem;
  grid-template-areas: "firstname firstname" "pesel pesel" "address address" "city postcode" "phone phone" "email email" "leasing leasing" "companyName regon";
}

.multipart-form-select {
  position: relative;
  z-index: 11;
}
.multipart-form-select * {
  z-index: 11;
}
.multipart-form-select:hover .multipart-form-select__triangle-container svg {
  transform: rotateZ(270deg);
}
.multipart-form-select:hover .multipart-form-select__options-container {
  display: flex !important;
}
.multipart-form-select--error .multipart-form-select__current-option {
  border-color: #ed4a57;
}
.multipart-form-select__current-option {
  padding: 0.625rem;
  display: flex;
  place-items: center;
  justify-content: space-between;
  border-bottom: 0.1875rem solid white;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.multipart-form-select__triangle-container {
  aspect-ratio: 1/1;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  place-items: center;
  justify-content: center;
}
.multipart-form-select__triangle-container svg {
  height: 40%;
  fill: white;
  transform: rotateZ(180deg);
  transition: transform 0.1s ease-in-out;
}
.multipart-form-select__options-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  display: none;
  flex-direction: column;
}
.multipart-form-select__options-container--hidden {
  border: 1px solid red;
}
.multipart-form-select__option {
  padding: 1rem 1rem;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  z-index: 11 !important;
  display: flex;
  place-items: center;
  gap: 1rem;
}
.multipart-form-select__option:nth-child(even) {
  background: #303030;
}
.multipart-form-select__option:nth-child(odd) {
  background: #404040;
}
.multipart-form-select__option-checkbox {
  width: 1.875rem;
  height: 1.875rem;
  appearance: none;
  border: 0.1875rem solid white;
  border-radius: 0.3125rem;
  cursor: pointer;
}
.multipart-form-select__option-checkbox:checked {
  background: var(--color-primary-500);
  border-color: var(--color-primary-500);
}

#car-insurance-firstname {
  grid-area: firstname;
}

#car-insurance-lastname {
  grid-area: lastname;
}

#car-insurance-pesel {
  grid-area: pesel;
}

#car-insurance-address {
  grid-area: address;
}

#car-insurance-city {
  grid-area: city;
}

#car-insurance-postcode {
  grid-area: postcode;
}

#car-insurance-phone {
  grid-area: phone;
}

#car-insurance-email {
  grid-area: email;
}

#car-insurance-companyName {
  grid-area: companyName;
}

#car-insurance-regon {
  grid-area: regon;
}

#insurance-form-car-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5625rem;
  grid-template-areas: "brand model" "vehicle-type production-year" "registration-number vin-number" "insurance-range policy-number";
}

#car-insurance-brand {
  grid-area: brand;
}

#car-insurance-model {
  grid-area: model;
}

#car-insurance-vehicle-type {
  grid-area: vehicle-type;
}

#car-insurance-production-year {
  grid-area: production-year;
}

#car-insurance-registration-number {
  grid-area: registration-number;
}

#car-insurance-vin-number {
  grid-area: vin-number;
}

#car-insurance-leasing {
  grid-area: leasing;
}

#car-insurance-insurance-range {
  grid-area: insurance-range;
}

#car-insurance-policy-number {
  grid-area: policy-number;
}

@media screen and (max-width: 1000px) {
  .multipart-form {
    padding: 1.5625rem;
  }
  .multipart-form__main-container {
    height: unset !important;
    flex-direction: column-reverse;
  }
  .multipart-form__navigation {
    width: 100%;
    flex-direction: column;
    gap: 2.25rem;
  }
  .multipart-form__navigation .multipart-form-checkbox {
    flex-shrink: 0;
  }
  .multipart-form__navigation-buttons {
    width: 100%;
    display: flex;
    place-items: center;
    justify-content: center;
  }
  .multipart-form-sendStatus {
    text-align: center;
  }

  .multipart-form-status {
    padding-left: 0;
    padding-bottom: 3.125rem;
  }

  .multipart-form-select--shown .multipart-form-select__triangle-container svg {
    transform: rotateZ(270deg);
  }
  .multipart-form-select--shown .multipart-form-select__options-container {
    display: flex !important;
  }

  .multipart-form-select:hover .multipart-form-select__triangle-container svg {
    transform: rotateZ(0);
  }
  .multipart-form-select:hover .multipart-form-select__options-container {
    display: none;
  }

  #insurance-form-personal-data,
#insurance-form-car-data {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-template-areas: unset !important;
  }

  .multipart-form__input {
    grid-area: unset !important;
  }
}
.multipart-form-sendStatus {
  height: 100%;
  color: white;
  fill: white;
  display: flex;
  place-items: center;
  justify-content: center;
  flex-direction: column;
}
.multipart-form-sendStatus svg {
  width: 10rem;
  margin-bottom: 2rem;
}
.multipart-form-sendStatus__title {
  font-size: 2rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
}
.multipart-form-sendStatus__description {
  margin-top: 0.25rem;
  font-size: 1.25rem;
  font-family: "Barlow", sans-serif;
  color: #dddddd;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  scroll-behavior: smooth;
  --height: 100vh;
}

.section-title {
  margin-bottom: 3.125rem;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
}

