@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

*{
    font-family: "Source Sans Pro", sans-serif;
}

@media screen and(min-width: 2000px) {
    :root{
        font-size: 20px;
    }
}

@media screen and (min-width: 3000px) {
    :root{
        font-size: 30px;
    }
}

@media screen and(min-width: 4000px) {
    :root{
        font-size: 40px;
    }
}

@media screen and(min-width: 5000px) {
    :root{
        font-size: 50px;
    }
}

@media screen and(min-width: 6000px) {
    :root{
        font-size: 55px;
    }
}

@media screen and(min-width: 7000px) {
    :root{
        font-size: 60px;
    }
}

@media screen and(min-width: 8000px) {
    :root{
        font-size: 65px;
    }
}


@media screen and(max-width: 1500px) {
    :root{
        font-size: 12px;
    }
}

@media screen and(max-width: 1000px) {
    :root{
        font-size: 10px;
    }
}